<template>
    <div class="phi-attendance-checkpoint">

        <div class="picker">
            <div class="select">
                <i class="fa fa-arrow-left direction leave" v-show="newRecord.type && newRecord.type.settings && newRecord.type.settings.direction < 0"></i>
                <i class="fa fa-arrow-right direction enter" v-show="newRecord.type && newRecord.type.settings && newRecord.type.settings.direction > 0"></i>
                <i class="fa fa-exclamation direction info" v-show="newRecord.type && (!newRecord.type.settings || !newRecord.type.settings.direction)"></i>

                <select v-model="newRecord.type">
                    <option v-for="ntype in types.items" :value="ntype" :key="ntype.singular">{{ ntype.singular }}</option>
                </select>
            </div>
            <phi-person-picker label="buscar" @select="selectPerson"></phi-person-picker>
        </div>

        <div class="person-list">
            <div v-for="(entry,k) in log.items" :key="k" class="phi-media entry">
                <div class="phi-media-figure phi-avatar">
                    <img :src="entry.person.avatar" alt="entry.person.firstName">
                </div>
                <div class="phi-media-body">
                    <h1 class="name">{{ entry.person.firstName }} {{ entry.person.lastName }}</h1>
                    <i class="fa fa-arrow-left direction leave" v-show="entry.direction < 0"></i>
                    <i class="fa fa-arrow-right direction enter" v-show="entry.direction > 0"></i>
                    <i class="fa fa-exclamation direction info" v-show="entry.direction == 0"></i>
                    <span class="type">{{ entry.type }}</span>
                    <span class="timestamp">{{ $date(entry.timestamp, "time") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhiPersonPicker from '../../../components/Phi/Person/Picker.vue';


export default {
    components: {PhiPersonPicker},

    props: {
        checkpoint: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            log: this.$store.state.api.collection(`/attendance/checkpoints/${this.checkpoint}/log`),
            types: this.$store.state.api.collection(`/types/checkpoint`),
            newRecord: {
                person: null,
                type: null
            }
        }
    },

    methods: {
        selectPerson(person) {
            if (this.log.items.length > 0 && person.id == this.log.items[0].person.id && this.newRecord.type.singular == this.log.items[0].type) {
                return;
            }

            this.newRecord.person = person;

            this.addLogEntry({
                person: this.newRecord.person.id,
                type:   this.newRecord.type.singular
            });
        },

        addLogEntry(entry) {
            this.$store.state.api
                .post(`/attendance/checkpoints/${this.checkpoint}/log`, entry)
                .then(createdEntry => this.log.unshift(createdEntry));
        }
    },

    mounted() {
        this.log.fetch();
        this.types.fetch().then(() => this.newRecord.type = this.types.items[0]);
    }
}
</script>

<style lang="scss">
.phi-attendance-checkpoint {

    .picker {
        position: relative;
        padding: 12px;

        .select {
            display: flex;

            & > .direction {
                display: block;
                padding: 6px;
                font-size: 12px;
            }

            & > select {
                flex: 1;

                font-size: 1em;
                border: none;
                background: transparent;
            }
        }


        .phi-person-picker {
            margin-top: 12px;
        }
    }

    .entry {
        .name {
            color: #000;
        }

        .direction {
            font-size: 11px;
            padding-right: 3px;
        }

        .timestamp {
            color: #777;
            font-size: 0.9em;
        }
    }

    .direction {
        &.enter {
            color: #23CF5F;
        }

        &.leave {
            color: #F64744;
        }

        &.info {
            color: #0F6698;
        }
    }
}

</style>