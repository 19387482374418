<template>
    <phi-page class="page" color="#1C89B8">
        <div slot="toolbar" class="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1>{{ $route.query.title }}</h1>
        </div>

        <div slot="header" class="header"></div>

        <phi-attendance-checkpoint :checkpoint="$route.params.nodeId" />
    </phi-page>
</template>

<script>
import PhiAttendanceCheckpoint from '../../components/Phi/Attendance/Checkpoint.vue';
export default {
    components: {PhiAttendanceCheckpoint},
    mounted() {
        this.$el.querySelectorAll('.picker, .person-list').forEach((elem) => {
            elem.classList.add('phi-card');
        });
    }
}
</script>

<style lang="scss">
$top-card-offset: 20px;
.page {
     .picker {
        position: relative;
        top: -$top-card-offset;
    }
}
</style>

<style lang="scss" scoped>
$top-card-offset: 20px;
.page {
    background-color: #1C89B8 !important;
}

.header {
    height: 40px;
}

.toolbar {
    color: #fff;
    h1 {
        color: #fff;
    }
}
</style>